<template>
  <el-upload
    class="avatar-uploader"
    :action="$store.state.uploadingUrl"
    :show-file-list="false"
    accept=".mp4"
    :on-progress="handProgress"
    :on-success="handleSuccess"
    :before-upload="beforeUpload"
  >

    <el-progress v-if="isUploading" type="circle" :percentage="percent"></el-progress>
    <video
      v-else-if="value"
      :src="value"
      width="100%"
      height="100%"
      controls
    ></video>
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    <div slot="tip" class="el-upload__tip">
      {{ tip }}
    </div>
  </el-upload>
</template>

<script>
function getRandom (n, m) {
    var num = (Math.random() * (m - n + 1) + n).toFixed(2)
    return num
}
export default {
  props: {
    tip: {
      type: String,
      default: '只能上传MP4文件，且不超过100M'
    },
    value: {
      type: [String, undefined, null],
      default: ''
    }
  },
  data() {
    return {
      isUploading: false,
      fileList: [],
      percent: 0
    }
  },
  methods: {
    handProgress(e) {
      this.isUploading = true
      this.percent = Number(e.percent < 90 ? e.percent.toFixed(2) : getRandom(98, 99))
    },
    handleSuccess(res) {
      this.isUploading = false
      this.$emit('input', `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${res.data}`)
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 100MB!");
      }
      return isLt2M;
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
/deep/ .el-upload--text {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>