<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="官网设置" name="first">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="ruleForm"
        > 
      <el-form-item prop="photos" label="官网轮播图">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          官网轮播图
        </p>
        <div style="display: flex">
          <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove2"
            :on-success="handleSuccess2"
            :file-list="photos"
            :multiple="true"
            :limit="5"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
            :visible.sync="dialogVisible"
            append-to-body
            :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
       <el-form-item label="地址" prop="address">
          <el-input id="suggestId" v-model="companyMsg.address"></el-input>
          <div id="baidumap"></div>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input id="suggestId" v-model="companyMsg.phone"></el-input>
        </el-form-item> 
      <el-form-item label="企业视频" prop="details">
        <el-button size="medium" @click="isShow = true">视频设置</el-button>
      </el-form-item> 
          <el-form-item label="企业介绍" prop="details">
          <quill-editor
            style="height: 100%"
            v-model="companyMsg.companyIntroduce"
            ref="myQuillEditor"
            :options="editorOption"
          />
         </el-form-item>
          <el-form-item label="产品介绍" prop="details">
          <quill-editor
            style="height: 100%"
            v-model="companyMsg.goodsIntroduce"
            ref="myQuillEditor"
            :options="editorOption"
          />
         </el-form-item>
          <el-form-item>
            <el-button
              class="save"
              type="primary"
              @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
         <el-dialog
            :modal-append-to-body="false"
            title="提示"
            :visible.sync="isShow"
            width="80%"
            height="100%"
            v-if="isShow"
          >
           <websiteVideoList :isShow="isShow"></websiteVideoList>
        </el-dialog>
   
        </el-form>
      </el-tab-pane>
    </el-tabs>
    
  </div>
</template>
<script>
import websiteVideoList from './websiteVideoList.vue'
import { mapState } from 'vuex';
import quillConfig from "@/utils/quill-config.js";
import {fetchIndustryList,addWebsiteInfo,selectWebsiteInfo} from '@/api/companyManage.js'
export default {
    prodClose() {
      this.isShow = false;
    },
  components: {
    websiteVideoList,
  },
  name: "corporateInformation",
  data() {
    return {
      editorOption: quillConfig,
      isShow:false,
      activeName: "first",
      disabled: false,
      ruleForm: {
        address:"",
        companyLogo: "",
        companyName: "",
        industryId: "",
        password: "",
        announcements: "",
      },
      rules: {
        companyLogo: [{ required: true, message: "请上传logo" }],
        companyName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        industryId: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入企业密令", trigger: "blur" },
        ],
      },
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      companyMsg:'', //公司信息
      industryList:'', //行业信息
      fileList:[],
      photos:[]
    };
  },
 /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      console.log(map)
      ac.addEventListener("onConfirm", this.setValue);
    },
  setValue(e) {
      const {
        item: { value },
      } = e;
      this.companyMsg.address = `${value.city}${value.district}${value.business}`;
    },
  mounted(){
    this.getCompany()
    this.getIndustryList()
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = []
      this.ruleForm.photos = ''
    },
     handleRemove2(file, photos) {
      this.photos = photos
      this.companyMsg.photos=this.photos.map((item) => item.url).join(",")
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
      handleSuccess2(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.photos = arr;
      this.companyMsg.photos = this.photos
      this.companyMsg.photos=this.companyMsg.photos.map((item) => item.url).join(",")
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    
    /**@method 提交表单 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.companyMsg.companyIntroduce=this.companyMsg.companyIntroduce?this.companyMsg.companyIntroduce.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block" '):''
          this.companyMsg.goodsIntroduce=this.companyMsg.goodsIntroduce?this.companyMsg.goodsIntroduce.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block" '):''
          addWebsiteInfo(this.companyMsg)
          .then(res=>{
            if(res.code == 200){
              this.$message({
                type:'success',
                message: "保存成功",
              })
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取公司信息
    getCompany(){
      selectWebsiteInfo({companyId:this.$store.state.loginRoot.userInfo.companyId})
      .then(res=>{
        this.companyMsg = res.data
        if(res.data.photos){
            this.photos = this.companyMsg.photos.split(",").map((item, index) => {
              return {
                url: item,
                uid: index,
              };
        });
        }
       
        // this.photos = [{url:this.companyMsg.companyLogo,uid:1}],
      })
    },
    //获取行业列表
    getIndustryList(){
      fetchIndustryList()
      .then(res=>{
        this.industryList = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
.corporateInformation-view {
  padding: 16px 24px;
}
/deep/.el-tabs__content{
  background: #ffffff;
}
.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
</style>